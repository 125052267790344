import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import "react-toastify/dist/ReactToastify.css";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import Help from "./Help";
import { withPrefix } from "gatsby";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: `${process.env.GATSBY_SENTRY_DSN}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const Layout = ({ title, children, isHomePage }) => {
  // Note: the site metadata has a title which is set for all pages (e.g. "Becca
  // & Aaron"). If a "title" prop is set on the Layout component, it will be be
  // appended to the doc title (e.g. "Becca & Aaron | Trave")
  const { title: docTitle, description } = useSiteMetadata();

  let fullDocTitle = docTitle;

  if (title) {
    fullDocTitle = `${docTitle} | ${title}`;
  }

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{fullDocTitle}</title>
        <meta name="robots" content="noindex" />
        <meta name="description" content={description} />
        <meta name="theme-color" content="#fff" />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
        <script
          type="text/javascript"
          src="https://identity.netlify.com/v1/netlify-identity-widget.js"
        />
      </Helmet>
      <Navbar disableBrand={false} disableBrandImage />
      {children}
      <ToastContainer />
      {!isHomePage && <Help />}
    </div>
  );
};

export default Layout;
