import React, { useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLifeRing } from "@fortawesome/free-solid-svg-icons";
import { getHelp } from "../ajax";
import { toast, Slide } from "react-toastify";

const HelpModal = ({ onClose }) => {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!(name && message)) {
      return;
    }

    setSending(true);

    getHelp({ name, message }).then((response) => {
      toast.success("We've received your request!", {
        hideProgressBar: true,
        transition: Slide,
        autoClose: 3000,
        closeOnClick: true,
      });

      setSending(false);
      setName("");
      setMessage("");
      onClose();
    });
  };

  const btnCls = classNames("button is-success", {
    "is-loading": sending,
  });

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal is-active">
        <div className="modal-background" onClick={onClose}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <div className="modal-card-title">Help!</div>
            <button
              className="delete"
              aria-label="close"
              onClick={onClose}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="content">
              <p>
                Having trouble? Oh no! This website was built by the bride and
                groom, so there may be some bugs, oops! If you're having trouble
                using the site, let use know! We'll help you out ASAP.
              </p>
            </div>
            <div className="field is-horizontal">
              <div className="field-label">
                <label htmlFor="message" className="label">
                  Name
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input
                      id="name"
                      className="input"
                      type="text"
                      placeholder="Your name, email, phone number, whatever!"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label">
                <label htmlFor="message" className="label">
                  Message
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <textarea
                      id="message"
                      className="textarea"
                      placeholder="Your message..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button onClick={onClose} className="button">
              Cancel
            </button>
            <button
              type="submit"
              disabled={!message || !name}
              className={btnCls}
            >
              Send
            </button>
          </footer>
        </div>
      </div>
    </form>
  );
};

const Help = () => {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 20,
        right: 20,
        zIndex: 100,
      }}
    >
      <button className="button" onClick={() => setOpen(!open)}>
        <span className="icon is-small">
          <FontAwesomeIcon icon={faLifeRing} size="xs" />
        </span>
        <span>Help!</span>
      </button>

      {open && <HelpModal onClose={() => setOpen(false)} />}
    </div>
  );
};

export default Help;
