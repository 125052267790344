import React, { useState } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { useLocation } from "@reach/router";
import { range } from "lodash";

const LINKS = [
  ["Our story", "/story"],
  ["Travel", "/travel"],
  ["Things to do", "/do"],
  ["Event info", "/info"],
  ["Covid-19", "/covid"],
  ["Registry", "/registry"],
  ["RSVP", "/rsvp"],
];

const Navbar = ({ disableBrand, disableBrandImage }) => {
  const [burgerActive, setBurgerActive] = useState(false);
  const location = useLocation();

  return (
    <div className="container">
      <section className="section p-0">
        <nav
          className="navbar mt-4"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            {!disableBrand && (
              <React.Fragment>
                {!disableBrandImage && (
                  <a className="navbar-item" href="/">
                    <img src="/img/BandA2.png" />
                  </a>
                )}
                <div className="navbar-item is-align-items-start" href="/">
                  <a className="title is-5" href="/">
                    B &amp; A
                  </a>
                </div>
              </React.Fragment>
            )}

            <a
              role="button"
              className={classNames("navbar-burger", {
                "is-active": burgerActive,
              })}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={() => {
                setBurgerActive(!burgerActive);
              }}
            >
              {range(3).map((i) => {
                return <span key={i} aria-hidden="true"></span>;
              })}
            </a>
          </div>

          <div
            className={classNames("navbar-menu", {
              "is-active": burgerActive,
            })}
          >
            <div className="navbar-end">
              {LINKS.map((link) => {
                const [label, href] = link;

                return (
                  <div key={href} className="navbar-item is-align-items-start">
                    <Link
                      className={classNames({
                        "is-active": location.pathname.startsWith(href),
                      })}
                      to={href}
                    >
                      {label}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
};

Navbar.defaultProps = {
  disableBrand: true,
  disableBrandImage: true,
};

export default Navbar;
